.chat-starters {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 10px;
    display: none
}

.chat-suggesst-wrapper {
    min-height: 270px
}

.chat-help-btns-wrapper-none, .start-central-column-bbtn-wrapper, .start-chat-suggesst-title, .start_answer_policy {
    display: none
}

.chat-suggesst-title-text {
    margin-bottom: 20px;
    width: 60%;
    line-height: 28px;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    font-weight: 500
}

.chat-suggesst-title-inner {
    gap: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center
}

.start-suggession-starter {
    position: unset !important
}

.suggetion-container button {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #8ecc3d;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .15);
    color: #575e71;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer
}

.suggetion-container button:hover {
    background: #f5f5f5
}

.suggetion-container {
    height: 100%
}

.suggetion-wrapper {
    height: 100%;
    min-height: 50px
}

.suggetion-icon img {
    width: 16px;
    padding-top: 3px
}

.suggetion-icon {
    display: flex;
    padding-left: 8px
}

.upload-img-wrapper {
    margin: auto auto 10px;
    height: 50px
}

.central-column-bbtn-wrapper {
    position: absolute;
    top: 45px;
    width: 100%;
    left: 0;
    z-index: 100;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #ffffff85;
    backdrop-filter: blur(8px) brightness(100%);
    -webkit-backdrop-filter: blur(8px) brightness(100%)
}

@media (min-width:767px) {
    .central-column-bbtn {
        height: 50px
    }
}

.image-upload {
    padding: 9px 2px 2px !important
}

.image-upload img {
    width: 35px;
    margin-right: 4px;
    padding: 6.5px
}

.image-upload img:hover {
    background: #0000000f
}

.chat-help-btns-stage {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 8px
}

.chat-help-btn-img {
    width: 100%;
    max-width: 110px
}

.central-column-btn-middle-img {
    width: 32px;
    height: 32px;
    margin-top: -2px;
    margin-right: 5px
}

.central-column-bbtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1400px;
    width: 100%
}

.central-greeting-user {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-left: 30px
}

.central-column-btn-middle-container, .central-greeting-user div {
    display: flex;
    align-items: center
}

.left-column .video-wrapper video {
    width: 100%;
    height: 80%;
    position: absolute;
    top: 80px;
    overflow: hidden;
    z-index: 10;
    object-fit: cover
}

.central-column-btn-middle-container span {
    font-weight: 500;
    line-height: 18px
}

.central-column-btn-middle-wrapper {
    padding-right: 12px;
    padding-left: 12px;
    width: 30%;
    display: flex;
    justify-content: center
}

.main-three-colum-layout-container, .main-three-colum-layout-wrapper {
    height: 100%;
    width: 100%
}

.central-column-btn-back {
    width: 37px;
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 10px;
    border-radius: 10px;
    background: rgba(0, 0, 0, .15)
}

@media (max-width:767px) {
    .chat-starters {
        grid-template-columns: repeat(1, 1fr);
        display: none
    }

    .chat-suggesst-wrapper {
        min-height: 270px
    }

    .chat-suggesst-title-text {
        margin-bottom: 20px;
        width: 80%;
        line-height: 28px;
        word-wrap: break-word;
        white-space: pre-wrap;
        overflow-wrap: anywhere
    }

    .central-column-bbtn-wrapper {
        top: 55px;
        height: 60px
    }

    .central-column-btn-right {
        display: none
    }

    .chat-help-btns-wrapper {
        margin-top: 5px
    }

    .central-column-btn-wrapper {
        margin: 5px 5px 5px 18px;
        height: 70px
    }

    .central-column-btn-back {
        width: 45px;
        margin-bottom: 1px;
        padding: 12px;
        border-radius: 10px;
        background: rgba(0, 0, 0, .2)
    }
}

.central-column-btn-back:hover {
    background: rgba(0, 0, 0, .2)
}

.central-column-btn-back span {
    font-size: 15px;
    font-weight: 500
}

.central-column-btn-wrapper {
    background: 0 0;
    width: 30%;
    margin: 4px 2px 2px 0;
    border: none;
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer
}

.central-column-btn-wrapper span {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500
}

.stage-2 span, .stage-3 span, .stage1-btn-wrapper span {
    color: #1a3054;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-style: normal;
    text-align: center;
    font-weight: 500
}

.chat-help-btn1 {
    max-width: 90px;
    width: auto;
    max-height: 110px;
    height: 100%;
    border: none;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .15)
}

.chat-help-btn-img-st2 {
    min-width: 45px;
    width: 100%;
    max-width: 46px
}

.stage-2-3-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10px
}

@media (min-width:992px) {
    .chat-help-btns-wrapper {
        width: 100%
    }

    .stage-2, .stage-3 {
        padding-left: 0 !important
    }

    .chat-help-btns-container, .stage-2 button, .stage-3 button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .stage-1 {
        width: 70%;
        padding-bottom: 10px
    }

    .stage-2 .button-hover:hover, .stage-3 .button-hover:hover {
        background: #fff !important
    }
}

.chat-help-btns-container .stage-2 button, .chat-help-btns-container .stage-3 button {
    border: 0 solid transparent;
    background: rgba(255, 255, 255, .9);
    box-shadow: inset 0 -6px 5px -1px rgba(0, 0, 0, .12), inset 0 0 1px -4px rgba(0, 0, 0, .12);
    padding: 10px;
    width: 100%;
    border-radius: 26px;
    cursor: pointer;
    display: flex;
    flex: 1;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 135px;
    min-height: 125px;
    min-width: 80px;
    backdrop-filter: blur(2.5px)
}

.chat-help-btns-stage .button:focus-visible {
    outline: 0
}

.stage-2 span, .stage-3 span {
    line-height: 16px
}

.stage1-btn-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.stage1-btn-wrapper span {
    line-height: 26px
}

.chat-help-btns-wrapper {
    display: block !important;
    width: 100%
}

@media (min-width:768px) {
    .chat-help-btns-wrapper {
        padding-left: 5px;
        padding-right: 5px;
        max-width: 960px;
        margin-top: 10px
    }
}

.autocomplete-dropdown {
    position: absolute;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #5792ff;
    border-radius: 4px;
    margin-top: -160px;
    z-index: 1;
    list-style-type: none;
    padding: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    color: #000
}

.autocomplete-item {
    padding: 8px 16px;
    cursor: pointer
}

.autocomplete-item:hover {
    background-color: #f0f0f0
}

.input-container-wrapper {
    width: 100%;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    padding-left: 10px;
    align-items: center
}

.q-and-a-container-wrapper {
    align-items: flex-end;
    min-height: 100vh;
    width: 100%;
    display: flex
}

.start-q-and-a-container {
    max-height: 100vh !important;
    min-height: unset !important
}

.start-main-inner-container {
    border-radius: 12px !important;
    margin: 0 !important;
    overflow: unset !important
}

.main-background-container .start-container {
    border-radius: 12px !important;
    align-items: center !important
}

.start-output-area {
    display: none !important
}

@media (min-width:1150px) {
    .main-three-colum-layout .start-right-column {
        position: absolute !important
    }
}

.start-input-container {
    width: 100% !important;
    max-width: 720px;
    margin-bottom: 0 !important;
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(1.5px)
}
.chat-type-wrapper{
    width: 92%;
    display: flex;
    justify-content: center;
    max-width: 560px;
margin-left: -14px;
}
.answer_policy {
    margin-bottom: 0;
    margin-top: 8px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center
}

.answer_policy div {
    color: #dddbdbbd;
    font-size: 12px;
    margin-top: 6px;
    
}

.answer_policy_link {
    font-weight: 400;
    color: #fff;
    text-decoration: none
}

@media (max-width:992px) {
    .start-main-three-colum-layout {
        background: #e9f2ff
    }
}

.main-background-container .main-inner-container .kps-chat-form .input-container .submit-button .chat-logo-record {
    max-width: 55px;
    color: #333;
    background: #fff;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .5);
    cursor: pointer;
    padding: 2px;
    transition: color 250ms;
    -webkit-transition: color 250ms;
    -moz-transition: color 250ms;
    -ms-transition: color 250ms;
    -o-transition: color 250ms;
    animation-name: wave;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite
}

@keyframes wave {
    0% {
        box-shadow: 0 0 0 0 #fc020280;
        border-radius: 15px
    }

    100% {
        box-shadow: 0 0 0 14px rgba(255, 255, 255, 0);
        border-radius: 18px
    }
}

.bacground-client-msg-bubble {
    background: #adc1e0 !important;
    padding-right: 10px !important;
    padding-top: 8px !important;
    padding-bottom: 11px !important
}

.left-client-msg-bubble {
    justify-content: flex-start !important;
    margin-left: 0 !important
}

.submit-voice, .voice-indicator {
    justify-content: center;
    display: flex
}

.left-client-msg-bubble .client-questions {
    border-radius: 21px 21px 21px 0 !important
}

scroll-container {
    overflow-y: hidden;
    will-change: transform;
    border: 1px solid #ccc;
    position: relative
}

.voice-indicator {
    width: 100px;
    border-radius: 50%;
    align-items: center;
    font-weight: 700;
    transition: background-color .2s ease-in-out
}

.voice-indicator.active {
    background-color: #0c0
}

.submit-voice {
    align-items: center;
    height: 100%
}

.submit-wrapper {
    margin: auto auto 0;
    height: 68px
}

.comman-button-text-input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97%;
    border: none;
    background: 0 0;
    border-radius: 25px;
    cursor: pointer
}

.mobile_1v3_nav_toggle {
    position: relative;
    align-items: center;
    z-index: 10000;
}

.comman-button-text-input-img {
    max-width: 65px;
    width: 55px;
    background: 0 0;
    border-radius: 15px;
}

.bot-msg-bubble-container {
    background: #0c2159;
    border-radius: 21px 21px 21px 3px;
    display: flex;
    padding: 15px 18px 15px 15px
}

.bot-msg-text {
    padding-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 65ch
}

.voice-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    background-color: #3498db;
    color: #fff;
    font-size: 24px
}

@media screen and (min-width:767px) {
    .chat-help-btns-container .stage-2 button, .chat-help-btns-container .stage-3 button {
        height: 135px
    }

    .chat-help-btns-container .stage-2 button:hover, .chat-help-btns-container .stage-3 button:hover {
        transform: scale(1.1)
    }

    .chat-help-btns-container .stage-2 button:hover img, .chat-help-btns-container .stage-3 button:hover img {
        filter: brightness(110%);
        transform: scale(1.03)
    }

    .stage-2 span, .stage-3 span {
        font-size: 15px;
        line-height: 18px
    }
}

@media (max-width:767px) {
    .answer_policy div {
        max-width: 280px;
    }
    
    .chat-help-btn-img-st2 {
        min-width: 40px;
        width: 100%;
        max-width: 42px
    }

    .answer_policy {
        margin-bottom: 0
    }

    .chat-help-btns-container .stage-2 button, .chat-help-btns-container .stage-3 button {
        min-height: 102px;
        max-height: 110px;
        min-width: 72px;
        height: 100%;
        border-radius: 18px;
        box-shadow: inset 0 -5px 5px -1px rgba(0, 0, 0, .12), inset 0 0 1px -4px rgba(0, 0, 0, .12);
        padding-bottom: 16px;
        gap: 6px
    }

    .stage-2, .stage-3 {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px
    }

    .sharebtn {
        margin-right: -35px
    }

    .sharebtn-wrapper {
        width: 30px
    }

    .sharebtn-inner {
        margin-left: 40px;
        width: auto
    }

    .central-column-btn-middle-wrapper {
        width: 38%
    }

    .tooltiptext {
        display: none
    }
}

.central-column-bbtn-inner {
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px
}
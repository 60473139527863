.answer-for-questions, .typewriter-text {
    font-family: Roboto, sans-serif;
    letter-spacing: .2px;
    font-style: normal
}

.answer-for-questions, .copy {
    align-items: center;
    background: 0 0
}

.copytool, .loading-model, .tooltip, .typewriter-text {
    position: relative
}

.response-wrapper {
    text-wrap: wrap;
    overflow-wrap: anywhere;
    white-space: pre-wrap
}

.svg-container:hover .cls-1 {
    fill: #FF0000 !important
}

.typewriter-text {
    left: 0;
    overflow: hidden;
    white-space: nowrap;
    animation: 1.5s forwards typing;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px
}

.copy {
    border: none;
    display: flex;
    padding-left: 10px
}

.copy-img:hover {
    border-radius: 10px
}

.copytool .copytooltext, .tooltip .tooltiptext {
    background-color: #000;
    text-align: center;
    border-radius: 6px;
    z-index: 1;
    color: #fff;
    position: absolute
}

.copy img {
    cursor: pointer;
    padding: 7px
}

.tooltip {
    display: inline-block
}

.tooltip .tooltiptext {
    width: 60px;
    padding: 5px 0;
    top: -58px;
    left: -40px
}

.copytool .copytooltext::after, .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent
}

.copytool .copytooltext {
    width: auto;
    padding: 5px 7px;
    bottom: 116%;
    left: -18%
}

/* @keyframes typing {
    0% {
        left: -100px;
        opacity: 0
    }

    50% {
        opacity: .5
    }

    100% {
        left: 0;
        opacity: 1
    }
} */

.loading-text {
    overflow: hidden;
    opacity: 0;
    width: 0;
    animation: .8s forwards loadingAnimation, .8s forwards scaleAnimation
}

@keyframes loadingAnimation {
    0% {
        width: 0;
        opacity: 0
    }

    50% {
        width: 0
    }

    100% {
        width: 150px;
        opacity: 1
    }
}

@keyframes scaleAnimation {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.answer-for-questions {
    word-wrap: break-word;
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    overflow-wrap: anywhere;
    text-align: left;
    white-space: pre-wrap;
    width: auto;
    margin: 0
}

.output-area, .output-area .q-and-a-container {
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto, sans-serif
}

.bot-msg-text, .voice-indicator {
    align-items: center;
    display: flex;
    justify-content: center
}

.bot-msg-text {
    max-width: 65ch;
    padding-left: 0;
    padding-top: 10px
}

.output-area .q-and-a-container .main-q-a-box {
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    gap: .6rem;
    width: 100%;
    margin-bottom: .8rem
}

.output-area .q-and-a-container .main-q-a-box .client-msg-bubble {
    display: flex;
    align-items: self-start;
    justify-content: flex-end
}

.left-client-msg-bubble {
    justify-content: flex-start !important;
    margin-left: 0 !important
}

.left-client-msg-bubble .client-questions {
    border-radius: 25px 25px 25px 0 !important
}

.bacground-client-msg-bubble {
    margin-bottom: 0 !important;
    background: #00020261 !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 12px !important
}

.lodinggif{
    height: 34px;
    padding-left: 28px;
    padding-right: 28px;
    border-radius: 15%;
    padding-top: 5px;
    padding-bottom: 5px;
    width: auto;
    background-color: #fff;
}

.loading-area {
    place-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 0
}

.loader {
    display: flex;
    flex-direction: row
}

.loading-text {
    padding: 12px 0 0;
    margin: 0 4px 3px 0;
    color: #232d72
}

.loading-model {
    width: 22px
}

.loading-model img {
    width: 27px;
    height: auto;
    position: absolute;
    top: -5px;
    right: 4px
}

.loading-area .loader .loading-sphere {
    color: #232d72;
    background: #232d72;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    transform: scale(0);
    animation: 1.5s ease-in-out infinite animate;
    display: inline-block;
    margin: 9px 4px 4px
}

.loading-area .loader .loading-sphere:first-child {
    color: #232d72;
    background: #232d72;
    animation-delay: 0s
}

.loading-area .loader .loading-sphere:nth-child(2) {
    color: #232d72;
    background: #232d72;
    animation-delay: .2s
}

.loading-area .loader .loading-sphere:nth-child(3) {
    color: #232d72;
    background: #232d72;
    animation-delay: .4s
}

.loading-area .loader .loading-sphere:nth-child(4) {
    animation-delay: .6s
}

.loading-area .loader .loading-sphere:nth-child(5) {
    animation-delay: .8s
}

.loading-area .loader .loading-sphere:nth-child(6) {
    animation-delay: 1s
}

.loading-area .loader .loading-sphere:nth-child(7) {
    animation-delay: 1.2s
}

@keyframes animate {
    0%, 100% {
        transform: scale(.2);
        background-color: #232d72
    }

    40%, 50% {
        transform: scale(1);
        background-color: #232d72
    }
}

.bot-msg-bubble .play-button-container button img {
    background: #fff;
    border-radius: 100%;
    height: 34px;
    margin: auto;
    opacity: .95;
    overflow: hidden;
    position: relative;
    transition: opacity .3s, box-shadow .3s;
    width: 34px
}

.bot-msg-bubble .play-button-container button {
    background: 0 0 !important;
    border: none !important;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 38px;
    padding: 0;
    text-align: center;
    width: 38px
}

.bot-msg-bubble .play-button-container {
    margin-top: 1px;
    min-width: 38px
}

.bot-msg-bubble-container {
    background: #0f3b57c4;
    border-radius: 21px;
    display: flex;
    flex-direction: column;
    padding: 20px 18px 20px 20px;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px)
}

.delay_greeting {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s, transform 1s
}

.delay_greeting.show_greeting {
    opacity: 1;
    transform: translateY(0)
}

.output-area .q-and-a-container .main-q-a-box .bot-msg-bubble {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin-right: 20px
}

@media (min-width:767px) {

    .bot-msg-bubble::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -40px;
        transform: translateY(-50%);
        border-width: 12px 20px;
        border-style: solid;
        border-color: transparent #0f3b57c4 transparent transparent
    }

    .mobile_welcome {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        line-height: 26px;
    }
}

.output-area .q-and-a-container .main-q-a-box .client-msg-bubble {
    align-items: self-start;
    display: flex;
    justify-content: flex-end;
    padding-right: 0
}

.output-area .q-and-a-container .main-q-a-box .client-msg-bubble .client-questions {
    width: fit-content;
    max-width: 65ch;
    font-family: roboto, sans-serif;
    background: #fff;
    border-radius: 21px 21px 5px;
    padding: 8px 20px 10px;
    display: flex;
    align-items: center;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    box-shadow: 0 -2px 4px 0 #00000040 inset;
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
    justify-content: space-around
}

.output-area .q-and-a-container .main-q-a-box .client-msg-bubble .client-questions div h2 {
    letter-spacing: .2px;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000;
    margin: 0
}

.output-area .q-and-a-container {
    text-wrap: wrap;
    white-space-collapse: collapse;
    margin: 0
}

.output-area {
    border-radius: 12px 12px 0 0;
    color: #000;
    height: 100%;
    margin: 0 auto;
    max-height: 100vh;
    min-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px 36PX 0 34PX;
    white-space: pre-wrap;
    width: 100%
}

@media (max-width:767px) {
    .delay_greeting.show_greeting {
        margin-right: 0px !important;
    }

    .mobile_welcome {
        display: none;
    }

    .output-area .q-and-a-container .main-q-a-box .client-msg-bubble {
        margin-left: 30px;
        padding-right: 0
    }

    .dotmenu-menu {
        font-size: 16px !important;
        gap: 12px !important;
        width: 170px !important
    }

    .mob_bg_overlay {
        background: #00000040;
        transition: 2s
    }

    .dotmenu-wrapper {
        bottom: -44px !important;
        right: 40px !important
    }

    .output-area {
        border-radius: 16px 16px 0 0;
        box-shadow: none;
        margin-bottom: 0;
        padding: 20px 20PX 0;
        min-height: 220px
    }
}

.chat-suggesst-title {
    color: #293041;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%
}

.chat-suggesst-img {
    width: 80px
}

.colored-path {
    width: 30px
}

.client-questions::selection {
    color: #0c2159;
    background: #fff
}

.answer-for-questions::selection {
    color: #0c2159;
    background: #fff
}

.play_loading_container svg {
    width: 90px;
    height: 34px;
    margin: auto;
    opacity: .95;
    overflow: hidden;
    position: relative
}

.play_loading_container {
    padding-bottom: 5px;
    background-color: #fff;
    border-radius: 12px;
    justify-content: center;
    display: flex;
    height: 34px;
    padding: 0;
    text-align: center;
    width: 90px
}

.copy-img-hover:hover {
    opacity: .8 !important
}

.play-button-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row
}

.play-button-menu {
    padding: 5px 5px 2px;
    background: #0000001a;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    height: 34px
}

.dotmenu-wrapper, .image_zoom_cover div img {
    border-radius: 10px;
    padding: 8px;
    background: #fff;
    display: flex
}

.play-button-menu:hover {
    background: #0000004a
}

.dotmenu-wrapper {
    position: absolute;
    width: 160px;
    bottom: -44px;
    right: 40px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .06), 0 1px 1px 2px rgba(0, 0, 0, .06), 0 1px 0 4px rgba(0, 0, 0, .06)
}

.dotmenu-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    gap: 5px
}

.dotmenu-menu {
    padding: 8px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    gap: 10px;
    color: #212121;
    font-weight: 500;
    font-size: 15px
}

.dotmenu-menu img {
    width: 18px
}

.dotmenu-menu:hover {
    background: #eaeff9
}

.preview_wrapper {
    width: 20%;
    display: flex
}

.preview_wrapper img {
    width: 100%
}

.image_zoom_cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    cursor: pointer
}

.image_zoom_cover .image_zoom_wrapper img {
    object-fit: contain;
    width: 90%;
    border-radius: 16px;
    max-height: 100%
}

.image_zoom_img {
    margin: auto 0 auto auto !important;
    background: #ffffff20 !important
}

.image_zoom_img:hover {
    background: #ffffff40 !important
}

.image_zoom_cover div img {
    width: 40px;
    margin: auto
}

.image_zoom_cover .image_zoom_wrapper div {
    width: 90%;
    padding: 20px 0 15px
}

.image_zoom_wrapper {
    width: 100%;
    border-radius: 14px;
    align-items: center;
    display: flex;
    flex-direction: column
}

.image_zoom_image {
    width: 90%;
    margin-bottom: 10px
}
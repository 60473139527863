.autocomplete-dropdown {
    position: absolute;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #5792ff;
    border-radius: 4px;
    margin-top: -176px;
    right: 0;
    z-index: 1;
    list-style-type: none;
    padding: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    color: #000;
    display: none
}

.autocomplete-item {
    padding: 8px 16px;
    cursor: pointer
}

.autocomplete-item:hover {
    background-color: #f0f0f0
}

.input-container-wrapper {
    width: 100%;
    min-height: 74px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    align-items: center;
    margin: auto;
    background: hsla(0, 0%, 100%, .885);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .06), 0 2px 7px 0 rgba(0, 0, 0, .06), 0 0 0 0 rgba(0, 0, 0, .06);
    border-radius: 25px;
    border: 1px solid;
    position: relative
}

.input-container-wrapper:focus-within {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .25)
}

.input-container-wrapper:hover {
    background-color: #fff !important
}

#yourVideoDivId video {
    margin-right: 2px;
    margin-top: 50px;
    margin-bottom: 35px;
    position: absolute;
    width: 100%;
    top: -35px;
    object-fit: cover
}

@media (max-width:995px) {
    .main-background-container .start-main-inner-container {
        border-radius: 0 !important
    }
}

@media (max-width:767px) {
    .main-background-container .start-main-inner-container {
        background: 0 0 !important
    }

    .start-video {
        margin-top: 50px
    }

    .input-container-wrapper {
        min-height: 70px;
        padding-left: 5px
    }

    #yourVideoDivId video {
        object-fit: cover;
        margin-right: 0;
        margin-top: 48px;
        margin-bottom: 0;
        width: 100vw !important;
        height: 420px
    }
}

.start-root {
    display: flex
}
.popup, .popup-content, .popup-wrapper {
    display: flex;
    width: 100%
}

.popup-mic-title{
    max-width: 280px;

}

.popup-mic-title h4{
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    color: #000;
    margin-bottom:0px;
    margin-top:10px;
}

.popup-mic-text {
    max-width: 280px;
}

.popup-mic-text p {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.58px;
    text-align: center;
    color: #616161;
}


.popup-mic-button button:hover {
    background:#458deb;
}

.popup-mic-button button {
    background: #3c85e5;
    padding: 10px 20px;
    margin-top: 5px;
    width: 140px;
    height: 45px;
    border-radius: 10px;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 20.48px;
    text-align: center;
    color: #fff;
    cursor: pointer;

}

.close-button {
    background-color: transparent
}

.close-button img {
    width: 32px;
    opacity: 1;
    padding: 5px;
    border-radius: 10px
}

.close-button:hover {
    opacity: 1;
    background: #0000001a
}

.popup-wrapper {
    height: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: #00000070;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.popup {
    margin: auto;
    max-width: 400px;
    border: 1px solid #3c85e54d;
    padding: 30px;
    background: #fff;
    border-radius: 10px
}

.popup-content {
    flex-direction: column;
    align-items: center
}

.close {
    align-self: flex-end;
    cursor: pointer;
    font-size: 20px
}

.popup-mic-img img{
max-width: 80px;
}
.desk_home_video_container_share, .desk_home_video_wrapper_share {
    position: absolute;
    width: 100%;
    height: 100%
}

.aspira-bot-profile{
    padding: 8px 10px;
    border-radius: 10px;
    background: #00000021;
    display: flex;
    margin-bottom: 5px;
}

.user-message-details {
    width: 103px;
    display: flex;
    margin: auto 0 auto auto;
}

.user-message-details{
    padding: 8px 10px;
    border-radius: 10px;
    background: #00000021;
}

.desk_home_video_wrapper_share {
    display: flex;
    justify-content: flex-end
}

.rosapark-logo-wrapper {
    max-width: 1300px;
    width: 100%;
    display: flex
}

.share-container {
    text-align: center;
    width: 100%;
    position: relative;
    padding-bottom: 20px
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    z-index: 10
}

.title {
    font-weight: 600;
    color: #fff
}

.share-middle-container-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center
}

.share-icon {
    left: 30px;
    max-width: 275px !important;
    padding-bottom: 10px;
    position: absolute;
    top: 30px;
    vertical-align: middle
}

.bot-answer, .client-question {
    max-width: 70ch
}

.date {
    font-size: 16px;
    color: #333;
    margin-top: 20px
}

.conversation-list {
    height: calc(100vh - 165px);
    margin-bottom: 0;
    margin-top: 2px;
    list-style: none;
    padding: 0;
    min-width: 200px
}

.message-container {
    text-align: center
}

.knightingale-message .title, .user-message .title {
    margin-right: 5px;
}

.knightingale-message, .user-message {
    text-align: left;
    font-weight: 400
}

.knightingale-message .message-icon, .user-message .message-icon {
    width: 28px;
    display: inline-block;
    text-align: center
}

.response {
    margin: 0
}

.message-icon {
    margin-right: 5px;
    vertical-align: middle;
    width: 20px
}

.knightingale-message img {
    width: 28px !important;
    padding-bottom: 0px
}

.knightingale-message .title {
    line-height: 28px;
    padding-bottom: 0px;
    color: #fff;
    font-size: 16px;
    font-weight: 500
}

@media (max-aspect-ratio:177/100) {
    .share_video {
        height: 100% !important
    }
}

@media (min-aspect-ratio:178/100) {
    .share_video {
        height: auto !important
    }
}

.knightingale-message-details {
    display: flex;
    gap: 10px
}

.knightingale-message .message-icon img, .play-button-container, .share_footer_btn_container, .user-message .message-icon img {
    width: 100%
}

.user-message-container {
    display: flex;
    justify-content: flex-end
}

.knightingale-message-container, .share_footer, .types_details {
    justify-content: flex-start;
    display: flex
}

.bot-answer {
    flex-direction: column;
    position: relative;
    margin-right: 50px;
    gap: 10px
}

.share-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    background: #f4f8fe;
    flex-direction: column;
    align-items: center
}

.share_container {
    display: flex;
    width: 100%;
    height: 100%
}

.share_footer_left, .share_knightigale_wrapper {
    width: 48%
}

.play-audio-button {
    background: 0 0;
    border: none;
    padding-left: 0
}

.play-audio-button img {
    width: 90px !important;
    padding-bottom: 0;
    height: 34px;
    cursor: pointer
}

.share_knightigale_wrapper video {
    height: 100%;
    max-height: 100%;
    width: 100%;
    border-radius: 0
}

.share_knightingale_conversation {
    width: 42%;
    padding: 0 15px;
    position: relative;
    z-index: 10000
}

.share_wrapper {
    display: flex
}

.type_option_text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%
}

.types_details_date p, .types_details_title {
    font-family: Roboto, sans-serif;
    font-weight: 600;
    font-style: normal
}

.type_option_img img {
    width: 32px;
    height: 32px
}

.type_option_inner {
    padding: 6px 10px;
    display: flex;
    gap: 8px;
    border-radius: 10px;
    min-width: 180px;
    justify-content: center
}

.types_container {
    display: flex;
    justify-content: space-between
}

.types_details {
    flex-direction: column
}

.types_details_date {
    display: flex;
    justify-content: flex-start;
    padding-left: 15px
}

.types_details_title {
    color: #fff;
    text-align: left;
    font-size: 22px;
    padding: 0 15px
}

.types_details_date p {
    color: #ffffffb5;
    text-align: center;
    font-size: 16px;
    margin: 10px 0
}

.share_footer_btn {
    padding: 2px 10px 10px;
    background: linear-gradient();
    width: 100%
}

.share_footer_btn a {
    display: flex;
    width: 100%;
    height: 65px;
    padding: 12px 15px;
    border-radius: 10px;
    background: linear-gradient(90deg, #0796d4 .89%, #1980bcc4 98.66%);
    text-decoration: none;
    font-family: roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: .2px;
    align-items: center;
    color: #fff;
    word-wrap: break-word;
    text-align: center;
    justify-content: center;
    white-space: pre-wrap;
    overflow-wrap: anywhere
}

.conversation-balance-top {
    display: none
}

@media (max-width:767px) {
    .share_footer_btn a {
        font-size: 20px;
        line-height: 20px
    }

    .desktop-container-chat-video {
        display: none
    }

    .conversation-balance-top {
        display: block
    }

    .rosapark-logo-wrapper a{
        display: flex;
        justify-content: center;
    }
    .share-icon {
        max-width: 275px!important;
        /* width: 60px; */
        top: 30px!important;
        left:unset!important;
        width: 275px!important;
    }

    .rosapark-logo-wrapper {
        justify-content: center;
    }

    .share_footer_left {
        width: 0%
    }

    .share_knightigale_wrapper {
        position: absolute;
        height: 100vh;
        width: 100%
    }

    .share_knightingale_conversation {
        width: 100%;
        padding: 0
    }

    .types_container {
        flex-direction: column
    }

    .types_details, .types_details_date {
        justify-content: center
    }

    .types_details {
        display: flex
    }

    .types_details_title {
        text-align: center;
        padding: 15px;
        background: #125d8996;
        border-radius: 15px;
        backdrop-filter: blur(2px);
        margin-top: 33vh;
    }
    .client-question {
        box-shadow: none!important;
    }
    .share_footer {
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        justify-content: center
    }

    .conversation-list {
        padding-right: 20px;
        padding-left: 20px;
        height: calc(100vh - 148px)
    }
}

@media (min-width:767px) {
    .mobile-container-chat-video {
        display: none
    }

    .conversation-list {
        height: calc(100%);
        padding-right: 20px
    }

    .share_container {
        height: 100%
    }

    .share_middle_wrapper {
        height: 90%
    }

    .bot-answer::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -40px;
        transform: translateY(-50%);
        border-width: 12px 20px;
        border-style: solid;
        border-color: transparent #00000099 transparent transparent
    }

    .types_wrappper {
        padding-top: 20px
    }

    .share_footer_btn_container {
        display: flex;
        width: 42%;
        justify-content: center;
        position: relative
    }

    .types_option {
        margin-right: 10px
    }
}